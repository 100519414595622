import { checkUserRole } from './authHandler';
import { supabase } from '../utils/supabase';
import { getProductById } from 'supabase-connect';

export const getOne = async (resource, params, baseDataProvider) => {
  if (resource === 'users') {
    try {
      const userRole = await checkUserRole();

      if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
        throw new Error('Only admins can view user details');
      }

      const { data: user, error } = await supabase
        .from('user_details')
        .select('*')
        .eq('id', params.id)
        .single();

      if (error) throw error;

      return { data: user };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in getOne for users:', error);
      throw error;
    }
  } else if (resource === 'products') {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message:
          "Access denied: You don't have permission to view this product",
      });
    }

    const data = await getProductById(params.id);

    const formattedConfigurations = Object.entries(
      data.product_configurations || {}
    ).flatMap(([category, options]) =>
      options.map(option => ({ category, value: option.value, id: option.id }))
    );

    return {
      data: {
        ...data,
        configurations: formattedConfigurations,
      },
    };
  } else if (resource === 'enquiries' || resource === 'quotations') {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: `Access denied: You don't have permission to view this ${resource.slice(
          0,
          -1
        )}`,
      });
    }

    if (resource === 'enquiries') {
      const { data, error } = await supabase
        .from(resource)
        .select(
          `
          *,
          user_details:user_id (email),
          enquiry_products (
            id,
            product_description,
            quantity,
            products:product_id (
            id,
            name,
            description,
            product_type,
            product_number,
            image_url
          ),
          enquiry_configurations (
            configuration_options (
              id,
              value,
              category
            )
          )
          )
        `
        )
        .eq('id', params.id)
        .single();

      if (error) {
        // eslint-disable-next-line no-console
        console.error(`Error fetching ${resource.slice(0, -1)}:`, error);
        throw error;
      }

      return { data };
    } else if (resource === 'quotations') {
      const { data, error } = await supabase
        .from(resource)
        .select(
          `
            *,
            enquiry:enquiry_id (
                items: enquiry_products (
                    id,
                    product_description,
                    quantity,
                    products:product_id (id, name, product_type, description, product_number, image_url)
                )
            ),
            items:quotation_products (
                id,
                quantity,
                price,
                user_selected,
                products:product_id (id, name, product_type, description, product_number, image_url),
                quotation_configurations (
                    configuration_options (
                        id,
                        value,
                        category
                    )
                )
            )
          `
        )
        .eq('id', params.id)
        .single();

      if (error) {
        // eslint-disable-next-line no-console
        console.error(`Error fetching ${resource.slice(0, -1)}:`, error);
        throw error;
      }

      // Reshape the data to match what your Show component expects
      data.products = data.items.map((item, index) => ({
        id: item?.products?.id,
        name: item?.products?.name,
        quantity: item?.quantity,
        product_type: item?.products?.product_type,
        image_url: item?.products?.image_url,
        product_description: item?.products?.description,
        product_number: item?.products?.product_number,
        price: item?.price,
        user_selected: item?.user_selected,
        description:
          data.enquiry?.items[index]?.product_description ||
          item?.product_description,
        configurations: item.quotation_configurations.map(config => ({
          id: config.configuration_options.id,
          value: config.configuration_options.value,
          category: config.configuration_options.category,
        })),
      }));

      return { data };
    }
  } else if (resource === 'invoices') {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: `Access denied: You don't have permission to view this ${resource.slice(
          0,
          -1
        )}`,
      });
    }

    const { data, error } = await supabase
      .from(resource)
      .select(
        `
        *,
        quotation_id (
          *,
          enquiry_id (
          id,
          status,
          user_details (
          email
          ),
          enquiry_number,
          tracking_url,
          tracking_information
          ),
          quotation_products (
            id,
            quantity,
            price,
            user_selected,
            product_id (
              id,
              product_number,
              name,
              description,
              image_url,
              product_type
            ),
            quotation_configurations (
              configuration_options (
                id,
                value,
                category
              )
            )
          )
        ),
        payments (
          id,
          payment_date,
          confirmed,
          file_path,
          updated_at,
          payment_confirm_at
        )
        `
      )
      .eq('id', params.id)
      .eq('quotation_id.quotation_products.user_selected', true)
      .single();

    if (error) {
      throw new Error(error.message);
    }

    return {
      data: {
        id: data?.id,
        invoiceNumber: data?.invoice_number,
        userId: data?.user_id,
        invoiceAmount: data?.invoice_amount,
        status: data?.status,
        pdfUrl: data?.pdf_url,
        createdAt: new Date(data?.created_at)?.toISOString(),
        updatedAt: new Date(data?.updated_at)?.toISOString(),
        trackingUrl: data?.quotation_id?.enquiry_id?.tracking_url,
        trackingInformation:
          data?.quotation_id?.enquiry_id?.tracking_information,
        enquiryId: data?.quotation_id?.enquiry_id?.id,
        enquiryNumber: data?.quotation_id?.enquiry_id?.enquiry_number,
        enquiryStatus: data?.quotation_id?.enquiry_id?.status,
        userEmail: data?.quotation_id?.enquiry_id?.user_details?.email,
        quotation: {
          id: data?.quotation_id?.id,
          status: data?.quotation_id?.status,
          remarks: data?.quotation_id?.remarks,
          version: data?.quotation_id?.version,
          createdAt: new Date(data?.quotation_id?.created_at)?.toISOString(),
          createdBy: data?.quotation_id?.created_by,
          enquiryId: data?.quotation_id?.enquiry_id,
          updatedAt: new Date(data?.quotation_id?.updated_at)?.toISOString(),
          quotationAmount: data?.quotation_id?.quotation_amount,
          quotationNumber: data?.quotation_id?.quotation_number,
          products: data?.quotation_id?.quotation_products?.map(product => ({
            id: product?.id,
            quantity: product?.quantity,
            price: product?.price,
            userSelected: product?.user_selected,
            product: product?.product_id
              ? {
                  id: product?.product_id?.id,
                  name: product?.product_id?.name,
                  description: product?.product_id?.description,
                  productNumber: product?.product_id?.product_number,
                  imageUrl: product?.product_id?.image_url,
                  productType: product?.product_id?.product_type,
                }
              : null,
            quotationConfigurations:
              product?.quotation_configurations?.map(config => ({
                configurationId: config?.configuration_options?.id,
                value: config?.configuration_options?.value,
                category: config?.configuration_options?.category,
              })) || [],
          })),
        },
        payments: data?.payments
          ?.map(payment => ({
            id: payment?.id,
            verifiedPayment: payment?.confirmed,
            paymentDate: payment?.payment_date,
            paymentConfirmAt: payment?.payment_confirm_at,
            paymentFile: payment?.file_path,
            updatedAt: new Date(payment?.updated_at)?.toISOString(),
          }))
          .sort((a, b) => new Date(b?.paymentDate) - new Date(a?.paymentDate)),
      },
    };
  } else if (resource === 'payments') {
    const { data, error } = await supabase
      .from('payments')
      .select(
        `
        *,
        invoices(invoice_number)
        `
      )
      .eq('id', params.id)
      .single();

    if (error) {
      throw error;
    }

    const paymentDetail = {
      id: data?.id,
      invoice_id: data?.invoice_id,
      invoice_number: data?.invoices?.invoice_number,
      payment_date: data?.payment_date,
      verifiedPayment: data?.confirmed,
      updated_at: data?.updated_at,
      file_url: data?.file_path,
    };

    return { data: paymentDetail };
  } else {
    const userRole = await checkUserRole();
    if (!['ADMIN', 'SALES_MANAGER'].includes(userRole)) {
      return Promise.reject({
        message: `Access denied: You don't have permission to view this ${resource.slice(
          0,
          -1
        )}`,
      });
    }
  }
  return baseDataProvider.getOne(resource, params);
};
