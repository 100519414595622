import React from 'react';
import { DATE_FORMAT_OPTIONS } from '../../../constants';
import { List, Datagrid, TextField, DateField, NumberField } from 'react-admin';
import { FormatStatusField } from '../../../components/common-component/FormatStatusField/index.js';

import InvoiceFilter from './InvoiceFilter.js';

const InvoiceList = props => {
  return (
    <List
      {...props}
      resource="invoices"
      filters={<InvoiceFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="invoice_number" label="Invoice Number" />

        <DateField
          source="created_at"
          label="Created At"
          showTime
          options={DATE_FORMAT_OPTIONS}
        />

        <DateField
          source="updated_at"
          label="Updated At"
          showTime
          options={DATE_FORMAT_OPTIONS}
        />

        <NumberField source="invoice_amount" label="Amount" />

        <FormatStatusField source="status" />
      </Datagrid>
    </List>
  );
};

export default InvoiceList;
