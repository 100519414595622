import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { ResetPasswordButton } from './ResetPasswordButton';

import CustomSelectInput from '../../components/common-component/CustomSelectInput';

const EditActions = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ResetPasswordField = () => {
  const record = useRecordContext();
  return <ResetPasswordButton userId={record.id} />;
};

export const UserEdit = props => {
  const notify = useNotify();

  const onSuccess = () => {
    notify('User updated successfully', { type: 'success' });
  };

  return (
    <Edit {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm toolbar={<EditActions />}>
        <TextInput disabled source="id" />

        <TextInput disabled source="email" />

        <CustomSelectInput
          source="role"
          defaultValue="CUSTOMER"
          choices={[
            { id: 'ADMIN', name: 'Admin' },
            { id: 'SALES_MANAGER', name: 'Sales Manager' },
            { id: 'CUSTOMER', name: 'Customer' },
          ]}
        />

        <ResetPasswordField />
      </SimpleForm>
    </Edit>
  );
};
