import { useState, useEffect, useMemo } from 'react';
import {
  useGetOne,
  useNotify,
  useGetRecordId,
  useDataProvider,
} from 'react-admin';
import { ENQUIRY_STATUS } from '../constants';

export const useEnquiryData = () => {
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [allPricesFilled, setAllPricesFilled] = useState(false);

  const notify = useNotify();
  const id = useGetRecordId();
  const dataProvider = useDataProvider();

  const {
    data: enquiry,
    loading,
    error,
    refetch,
  } = useGetOne('enquiries', { id });

  const isQuotationGenerated = useMemo(
    () => enquiry?.status === ENQUIRY_STATUS?.QUOTATION_GENERATED,
    [enquiry]
  );

  const checkAllPricesFilled = products =>
    products.every(product => product.price > 0);

  useEffect(() => {
    if (enquiry && !isQuotationGenerated && enquiry.enquiry_products) {
      const updatedProducts = enquiry.enquiry_products.map(ep => ({
        ...ep.products,
        ...ep?.enquiry_configurations,
        quantity: ep.quantity,
        isCustom: ep?.product_description ? true : false,
        description: ep?.product_description,
        price: ep.price || 0,
      }));

      setProducts(updatedProducts);

      setAllPricesFilled(checkAllPricesFilled(updatedProducts));
    }
  }, [enquiry, isQuotationGenerated]);

  useEffect(() => {
    const newTotal = products.reduce((sum, p) => sum + p.price * p.quantity, 0);

    setTotal(newTotal);
  }, [products]);

  const handlePriceChange = (index, price) => {
    if (!isQuotationGenerated) {
      setProducts(prevProducts => {
        const updatedProducts = [...prevProducts];

        updatedProducts[index].price = price;

        setAllPricesFilled(checkAllPricesFilled(updatedProducts));

        return updatedProducts;
      });
    }
  };

  const handleSubmit = async () => {
    try {
      await dataProvider.generateQuotation({
        enquiryId: id,
        products: products?.map(p => ({
          product_id: p?.id,
          quantity: p?.quantity,
          price: p?.price,
          product_description: p?.isCustom ? p?.description : null,
          isCustom: p?.isCustom,
          configuration_options: Object.keys(p)
            .filter(key => !isNaN(key))
            .map(key => p[key]?.configuration_options?.id),
        })),
      });
      notify('Quotation generated successfully', 'success');

      refetch();
    } catch (error) {
      notify(`Error: ${error.message}`, 'error');
    }
  };

  return {
    total,
    error,
    enquiry,
    loading,
    products,
    handleSubmit,
    allPricesFilled,
    handlePriceChange,
    isQuotationGenerated,
  };
};
