import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { getRandomColor } from '../../common/helpers';

import StatCard from '../common-component/StatCard';

const StatsCardSection = ({ statCards }) => {
  const [randomColors, setRandomColors] = useState([]);

  useEffect(() => {
    const newColors = statCards.map(() => getRandomColor());
    setRandomColors(newColors);
  }, [statCards.length, statCards]);

  return (
    <Grid container spacing={3}>
      {statCards.map((card, index) => (
        <Grid item xs={12} md={6} lg={3} key={card.key}>
          <StatCard {...card} randomColor={randomColors[index]} />
        </Grid>
      ))}
    </Grid>
  );
};

export default StatsCardSection;
