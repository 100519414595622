import React from 'react';
import { Filter, TextInput, SelectInput } from 'react-admin';

const statusChoices = [
  { id: true, name: 'Verified Payment' },
  { id: false, name: 'Not Verified' },
];

const PaymentFilter = props => (
  <Filter {...props}>
    <TextInput
      source="q"
      label="Search by Invoice Number"
      variant="outlined"
      alwaysOn
    />
    <SelectInput
      source="confirmed"
      label="Filter by Status"
      choices={statusChoices}
      optionText="name"
      optionValue="id"
      alwaysOn
      variant="outlined"
      emptyText="All"
      emptyValue=""
    />
  </Filter>
);

export default PaymentFilter;
