import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  useRecordContext,
} from 'react-admin';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  Chip,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { DATE_FORMAT_OPTIONS } from '../../../constants';

const PaymentProofField = () => {
  const record = useRecordContext();
  if (!record || !record?.file_url) {
    return null;
  }
  const filePath = record?.file_url;
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<VisibilityIcon />}
      href={filePath}
      target="_blank"
      sx={{ width: '100%', maxWidth: '400px' }}
    >
      VIEW PAYMENT PROOF
    </Button>
  );
};

const StatusChip = () => {
  const record = useRecordContext();
  return (
    <Chip
      icon={<CancelIcon />}
      label={record?.verifiedPayment ? 'Confirmed' : 'Pending'}
      color={record?.verifiedPayment ? 'success' : 'warning'}
      size="small"
      sx={{ borderRadius: '4px', height: '24px' }}
    />
  );
};

export const PaymentShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent sx={{ '&:last-child': { pb: 2 } }}>
          <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
            Payment Details
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={0.5}>
                <Typography variant="body2" sx={{ mr: 1 }}>
                  Status:
                </Typography>

                <StatusChip />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                Invoice Number: <TextField source="invoice_number" />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" gap={2} mt={1}>
                <Typography variant="body2" color="textSecondary">
                  Payment Date:{' '}
                  <DateField
                    source="payment_date"
                    showTime={true}
                    options={DATE_FORMAT_OPTIONS}
                  />
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  Last Updated:{' '}
                  <DateField
                    source="updated_at"
                    showTime={true}
                    options={DATE_FORMAT_OPTIONS}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined">
        <CardContent sx={{ '&:last-child': { pb: 2 } }}>
          <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
            Payment Proof
          </Typography>

          <Box display="flex" mt={1}>
            <PaymentProofField />
          </Box>
        </CardContent>
      </Card>
    </SimpleShowLayout>
  </Show>
);
