import React from 'react';
import {
  Table,
  Input,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Box,
} from '@mui/material';
import { FormControl } from '@mui/material';
import { PRODUCT_TYPE } from '../../../constants';
import { formatCurrency, formatStatusName } from '../../../common/helpers';

import styles from './ProductsTable.module.css';

export const ProductsTable = ({
  total = 0,
  products,
  onPriceChange,
  isQuotationGenerated,
}) => {
  const renderConfigurationOptions = product => {
    const configOptions = Object.values(product).filter(
      value => typeof value === 'object' && value?.configuration_options
    );

    if (configOptions.length === 0) return null;

    return (
      <TableRow>
        <TableCell colSpan={5} className={styles.configCell}>
          <Box className={styles.configBox}>
            <Typography variant="subtitle2" className={styles.configTitle}>
              Vehicle Configuration:
            </Typography>

            <Typography variant="body2" className={styles.configOptions}>
              {configOptions.map((option, index) => (
                <span
                  key={option?.configuration_options?.id}
                  className={styles.configOption}
                >
                  <span className={styles.configLabel}>
                    {formatStatusName(option?.configuration_options?.category)}:
                  </span>{' '}
                  {option?.configuration_options?.value}
                  {index < configOptions.length - 1 ? ' | ' : ''}
                </span>
              ))}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className={styles.card}>
      <Box className={styles.cardTitle}>
        <Typography variant="h6">Products</Typography>
      </Box>

      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell className={styles.tableHeadCell}>Product Name</TableCell>
            <TableCell
              className={`${styles.tableHeadCell} ${styles.tableCellDescription}`}
            >
              Product Description
            </TableCell>

            <TableCell className={styles.tableHeadCell}>Quantity</TableCell>

            <TableCell className={styles.tableHeadCell}>Price / Unit</TableCell>

            <TableCell className={styles.tableHeadCell}>Sub-Total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {products.map((product, index) => (
            <React.Fragment key={product.id}>
              <TableRow>
                <TableCell
                  className={
                    (styles.tableCell,
                    product?.product_type === PRODUCT_TYPE?.VEHICLE &&
                      styles.borderNone)
                  }
                >
                  {product?.name || 'Custom Product'}
                </TableCell>

                <TableCell
                  className={`${
                    (styles.tableCell,
                    product?.product_type === PRODUCT_TYPE?.VEHICLE &&
                      styles.borderNone)
                  } ${styles.tableCellDescription}`}
                >
                  {product?.description || 'N/A'}
                </TableCell>

                <TableCell
                  className={
                    (styles.tableCell,
                    product?.product_type === PRODUCT_TYPE?.VEHICLE &&
                      styles.borderNone)
                  }
                >
                  {product.quantity}
                </TableCell>

                <TableCell
                  className={`${
                    (styles.tableCell,
                    product?.product_type === PRODUCT_TYPE?.VEHICLE &&
                      styles.borderNone)
                  } ${styles.tableCellPrice}`}
                >
                  <FormControl variant="standard">
                    <Input
                      value={product.price || ''}
                      onChange={e =>
                        onPriceChange(index, parseFloat(e.target.value) || 0)
                      }
                      className={styles.priceInput}
                      type="number"
                      placeholder="Enter price"
                      inputProps={{ step: '0.01' }}
                      disabled={isQuotationGenerated}
                      disableUnderline
                    />
                  </FormControl>
                </TableCell>

                <TableCell
                  className={`${
                    (styles.tableCell,
                    product?.product_type === PRODUCT_TYPE?.VEHICLE &&
                      styles.borderNone)
                  } ${styles.tableCellPrice}`}
                >
                  {formatCurrency(product.price * product.quantity)}
                </TableCell>
              </TableRow>
              {product?.product_type === PRODUCT_TYPE?.VEHICLE &&
                renderConfigurationOptions(product)}
            </React.Fragment>
          ))}
          <TableRow className={styles.totalRow}>
            <TableCell
              className={`${styles.tableCell} ${styles.tableCellPrice}`}
              colSpan={4}
            >
              Total
            </TableCell>

            <TableCell
              className={`${styles.tableCell} ${styles.tableCellPrice}`}
            >
              {formatCurrency(total)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
