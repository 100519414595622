import React from 'react';
import { initialize } from 'supabase-connect';
import { Admin, Resource } from 'react-admin';
import { RESOURCES, USER_ROLES } from './constants';
import { ProductEdit } from './resources/products/ProductEdit';
import { ProductShow } from './resources/products/ProductShow';
import { ProductList } from './resources/products/ProductList';
import { PaymentShow } from './resources/payments/paymentShow';
import { EnquiryList } from './resources/enquiries/EnquiryList';
import { PaymentList } from './resources/payments/payementList';
import { UserList, UserEdit, UserCreate } from './resources/users';
import { ProductCreate } from './resources/products/ProductCreate';
import { EnquiryCreate } from './resources/enquiries/EnquiryCreate';
import { EnquiryShow } from './resources/enquiries/EnquiryShow/EnquiryShow';
import { QuotationList } from './resources/quotations/QuotationList/QuotationList';

import UserIcon from '@mui/icons-material/Group';
import EnquiryIcon from '@mui/icons-material/LiveHelp';
import ProductIcon from '@mui/icons-material/ShoppingCart';
import PaymentsIcon from '@mui/icons-material/Payments';
import InvoiceIcon from '@mui/icons-material/Receipt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

import Login from './components/Login';
import Dashboard from './components/dashboard';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import CustomLayout from './components/CustomAppBar';
import InvoiceList from './resources/invoices/InvoiceList';
import InvoiceShow from './resources/invoices/InvoiceShow';
import QuotationShow from './resources/quotations/QuotationShow';

import './App.css';

const App = () => {
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

  if (!supabaseUrl || !supabaseAnonKey) {
    throw new Error('Supabase URL or Anon Key is missing');
  }

  initialize(supabaseUrl, supabaseAnonKey);

  return (
    <div className="app-container">
      <Admin
        layout={CustomLayout}
        authProvider={authProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard}
        loginPage={Login}
        title={route =>
          route === 'dashboard'
            ? 'Admin Dashboard'
            : `Orbit Auto World Admin - ${route}`
        }
      >
        {permissions => [
          permissions === USER_ROLES?.ADMIN && (
            <Resource
              name={RESOURCES?.USERS}
              icon={UserIcon}
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
            />
          ),

          [USER_ROLES?.ADMIN, USER_ROLES?.SALES_MANAGER].includes(
            permissions
          ) && (
            <Resource
              name={RESOURCES?.PRODUCTS}
              icon={ProductIcon}
              list={ProductList}
              edit={ProductEdit}
              create={ProductCreate}
              show={ProductShow}
            />
          ),

          [USER_ROLES?.ADMIN, USER_ROLES?.SALES_MANAGER].includes(
            permissions
          ) && (
            <Resource
              name={RESOURCES?.ENQUIRIES}
              list={EnquiryList}
              icon={EnquiryIcon}
              create={EnquiryCreate}
              show={EnquiryShow}
              recordRepresentation="enquiry_number"
            />
          ),

          [USER_ROLES?.ADMIN, USER_ROLES?.SALES_MANAGER].includes(
            permissions
          ) && (
            <Resource
              name={RESOURCES?.QUOTATIONS}
              list={QuotationList}
              show={QuotationShow}
              icon={RequestQuoteIcon}
              recordRepresentation="quotation_number"
            />
          ),

          [USER_ROLES?.ADMIN, USER_ROLES?.SALES_MANAGER].includes(
            permissions
          ) && (
            <Resource
              name={RESOURCES?.INVOICES}
              list={InvoiceList}
              show={InvoiceShow}
              icon={InvoiceIcon}
              recordRepresentation="invoice_number"
            />
          ),

          [USER_ROLES?.ADMIN, USER_ROLES?.SALES_MANAGER].includes(
            permissions
          ) && (
            <Resource
              name={RESOURCES?.PAYMENTS}
              list={PaymentList}
              show={PaymentShow}
              icon={PaymentsIcon}
              recordRepresentation="payment_id"
            />
          ),
        ]}
      </Admin>
    </div>
  );
};

export default App;
