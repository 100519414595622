import { supabase } from '../utils/supabase';
import { checkUserRole } from './authHandler';

const userHandler = {
  create: async params => {
    try {
      // Get the current user session
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      // Extract the access token
      const accessToken = sessionData?.session?.access_token;
      if (!accessToken) throw new Error('No access token available');

      // Check if the current user is an admin
      const userRole = await checkUserRole();

      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can create new users');
      }

      // Prepare data for the Edge Function
      const { email, password, role = 'CUSTOMER' } = params.data;

      // Call the Edge Function to create a new user using Supabase client
      const {
        data: { user: newUser },
        error: createUserError,
      } = await supabase.functions.invoke('create-user', {
        body: JSON.stringify({ email, password, role }),
      });

      if (createUserError)
        throw new Error(`Error creating user: ${createUserError.message}`);

      // Update the user role in the 'user_details' table
      const { error: updateRoleError } = await supabase
        .from('user_details')
        .update({ role })
        .eq('id', newUser.id);

      if (updateRoleError) throw updateRoleError;

      return {
        data: {
          id: newUser.id,
          email: newUser.email,
          role,
        },
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in create function for users:', error);
      throw new Error('User creation failed: ' + error.message);
    }
  },

  update: async params => {
    try {
      const { error: currentUserError } = await supabase.auth.getUser();
      if (currentUserError) throw currentUserError;

      const userRole = await checkUserRole();

      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can update user roles');
      }

      const { error: updateRoleError } = await supabase
        .from('user_details')
        .update({ role: params.data.role })
        .eq('id', params.id);

      if (updateRoleError) throw updateRoleError;

      return { data: { id: params.id, ...params.data } };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in update for users:', error);
      throw error;
    }
  },

  delete: async params => {
    try {
      const { error: currentUserError } = await supabase.auth.getUser();
      if (currentUserError) throw currentUserError;

      const userRole = await checkUserRole();

      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can delete users');
      }

      const { data, error: deleteError } = await supabase.rpc('delete_user', {
        user_id: params.id,
      });
      if (deleteError) throw deleteError;

      return { data: { id: data.id } };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in delete for users:', error);
      throw error;
    }
  },

  deleteMany: async params => {
    try {
      const { error: currentUserError } = await supabase.auth.getUser();
      if (currentUserError) throw currentUserError;

      const userRole = await checkUserRole();

      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can delete users');
      }

      const results = await Promise.all(
        params.ids.map(id =>
          supabase.rpc('delete_user', {
            user_id: id,
          })
        )
      );

      const failedDeletions = results.filter(({ error }) => error);
      if (failedDeletions.length > 0) {
        // eslint-disable-next-line no-console
        console.error('Some deletions failed:', failedDeletions);
        throw new Error('Some users could not be deleted');
      }

      return { data: params.ids };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in deleteMany for users:', error);
      throw error;
    }
  },

  resetPassword: async (userId, newPassword) => {
    try {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();

      if (sessionError) throw sessionError;

      const accessToken = sessionData?.session?.access_token;
      if (!accessToken) throw new Error('No access token available');

      const userRole = await checkUserRole();
      if (userRole !== 'ADMIN') {
        throw new Error('Only admins can reset passwords');
      }

      const { error: resetError } = await supabase.functions.invoke(
        'reset-password',
        {
          body: JSON.stringify({ userId, newPassword }),
        }
      );

      if (resetError) throw resetError;

      return { success: true };
    } catch (error) {
      throw new Error('Password reset failed: ' + error.message);
    }
  },
};

export default userHandler;
