import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from 'react-admin';

import { DATE_FORMAT_OPTIONS } from '../../../constants';

import PaymentFilter from './PaymentFilter.js';

export const PaymentList = props => (
  <List
    {...props}
    resource="payments"
    filters={<PaymentFilter />}
    sort={{ field: 'payment_date', order: 'DESC' }}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="invoice_number" label="Invoice Number" />

      <TextField source="user_email" label="User Email" />

      <DateField
        source="payment_date"
        label="Payment Date"
        showTime
        options={DATE_FORMAT_OPTIONS}
      />

      <DateField
        source="updated_at"
        label="Last Updated"
        showTime
        options={DATE_FORMAT_OPTIONS}
      />
      <BooleanField source="confirmed" label="Confirmed" />
    </Datagrid>
  </List>
);
