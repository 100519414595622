import {
  login,
  checkError,
  checkAuth,
  logout,
  getIdentity,
  getPermissions,
} from 'supabase-connect';

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const user = await login({ username, password });

      if (user.role === 'CUSTOMER') {
        return Promise.reject('Not authorized to access admin panel');
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  checkError: async error => {
    await checkError(error);
  },

  checkAuth: async () => {
    try {
      await checkAuth();
      return Promise.resolve();
    } catch (error) {
      return Promise.reject('No active session');
    }
  },

  getIdentity: async () => {
    try {
      const identity = await getIdentity();
      return Promise.resolve({
        ...identity,
        fullName: identity.email,
      });
    } catch (error) {
      return Promise.reject('No user found');
    }
  },

  getPermissions: async () => {
    try {
      const role = await getPermissions();
      return Promise.resolve(role);
    } catch (error) {
      return Promise.reject('No user found');
    }
  },

  logout: async () => {
    try {
      await logout();
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
