import React from 'react';
import {
  SimpleForm,
  TextInput,
  PasswordInput,
  useCreate,
  useNotify,
  useRedirect,
} from 'react-admin';
import CustomSelectInput from '../../components/common-component/CustomSelectInput';

export const UserCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSubmit = async values => {
    try {
      await create('users', { data: values });
      notify('User created successfully', { type: 'success' });
      redirect('list', 'users');
    } catch (error) {
      notify(`Error: ${error.message}`, { type: 'warning' });
    }
  };

  return (
    <SimpleForm onSubmit={onSubmit}>
      <TextInput source="email" />

      <PasswordInput source="password" />

      <CustomSelectInput
        source="role"
        defaultValue="CUSTOMER"
        choices={[
          { id: 'ADMIN', name: 'Admin' },
          { id: 'SALES_MANAGER', name: 'Sales Manager' },
          { id: 'CUSTOMER', name: 'Customer' },
        ]}
      />
    </SimpleForm>
  );
};
