import React, { useState } from 'react';
import { ENQUIRY_STATUS } from '../../../constants';
import { ProductsTable } from './ProductsTable';
import { useEnquiryData } from '../../../hooks/useEnquiryData';
import { Link, SimpleForm, useNotify, Toolbar, useRefresh } from 'react-admin';
import {
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';

import styles from './ProductsTable.module.css';

const EnquiryQuotation = () => {
  const {
    total,
    error,
    enquiry,
    loading,
    products,
    handleSubmit,
    allPricesFilled,
    handlePriceChange,
    isQuotationGenerated,
  } = useEnquiryData();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="enquiry-card">
      <SimpleForm
        mutationMode="pessimistic"
        toolbar={
          <GenerateQuotationToolbar
            onSubmit={handleSubmit}
            quotationId={enquiry?.quotation_id}
            enquiryStatus={enquiry?.status}
            allPricesFilled={allPricesFilled}
            isQuotationGenerated={isQuotationGenerated}
          />
        }
      >
        <ProductsTable
          total={total}
          products={products}
          onPriceChange={handlePriceChange}
          isQuotationGenerated={isQuotationGenerated}
        />
      </SimpleForm>
    </div>
  );
};

const GenerateQuotationToolbar = ({
  onSubmit,
  quotationId,
  enquiryStatus,
  allPricesFilled,
  isQuotationGenerated,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const handleSubmit = async () => {
    try {
      if (!allPricesFilled) {
        notify('Please fill all prices before generating the quotation', {
          type: 'warning',
        });
        return;
      }
      if (isQuotationGenerated) {
        notify('Quotation already generated', { type: 'info' });
        return;
      }

      handleOpenConfirmation();
    } catch (error) {
      notify(`Error generating quotation: ${error.message}`, { type: 'error' });
    }
  };

  const handleConfirmSubmit = async () => {
    handleCloseConfirmation();
    setLoading(true);
    try {
      await onSubmit();
      notify('Quotation successfully generated', { type: 'success' });
      refresh();
    } catch (error) {
      notify(`Error generating quotation: ${error.message}`, { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = !allPricesFilled || isQuotationGenerated || loading;

  return (
    <>
      <Toolbar style={{ backgroundColor: 'inherit' }}>
        {!isQuotationGenerated &&
          enquiryStatus === ENQUIRY_STATUS?.SUBMITTED && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={styles.button}
              disabled={isDisabled}
              style={{ position: 'relative' }}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  color="inherit"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    marginLeft: -12,
                    marginTop: -12,
                  }}
                />
              )}
              {!loading && 'Generate Quotation'}
            </Button>
          )}

        {isQuotationGenerated && (
          <Button
            component={Link}
            to={`/quotations/${quotationId}/show`}
            color="secondary"
            style={{ marginLeft: '10px' }}
          >
            View Quotation
          </Button>
        )}
      </Toolbar>

      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        sx={{ position: 'absolute', bottom: 0 }}
      >
        <DialogTitle>Confirm Quotation Generation</DialogTitle>

        <DialogContent>
          <Typography>
            Are you sure you want to generate the quotation?
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseConfirmation}>Cancel</Button>

          <Button
            onClick={handleConfirmSubmit}
            color="primary"
            disabled={loading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EnquiryQuotation;
